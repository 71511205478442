import * as React from 'react'
import { Box, Flex } from 'theme-ui'
import { GeometricAnimation } from '../../components/GeometricAnimation'
import { MenuItem } from '../../components/Menu'
import { SEO } from '../../components/SEO'
import { TextGradient } from '../../components/TextGradient'
import { LINKS } from '../../constants'
import { GlobalLayout } from '../../layouts/global'
import { PageProps } from '../../utils/types'

const Page = React.memo<PageProps<void>>(({ location }) => {
  return (
    <>
      <SEO title="Hey !" location={location} />
      <GlobalLayout>
        <Box
          as="h1"
          sx={{
            mt: [4, 5],
            px: [4, 5],
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [4, 5],
            letterSpacing: [1, 2],
            maxWidth: [null, '600px'],
          }}
        >
          Développeur et éternel insatisfait<TextGradient>.</TextGradient>
        </Box>

        <Box
          sx={{
            mt: [4, 5],
            px: [4, 5],
            fontFamily: 'body',
            fontWeight: 'body',
            fontSize: 1,
            letterSpacing: 0,
            maxWidth: [null, '600px'],
            alignSelf: [null, 'flex-end'],
            textAlign: [null, 'right'],
          }}
        >
          Passionné par l’informatique depuis l’âge de 10 ans, je suis
          perpétuellement en quête de nouvelles technologies à tester et à
          appréhender.
          <br />
          <br />
          Je me spécialise aujourd'hui dans le développement JavaScript,
          TypeScript, Node.js et React.
        </Box>

        <Flex
          sx={{
            mt: [4, 5],
            p: [4, 5],
            backgroundColor: 'detail',
          }}
        >
          <GeometricAnimation
            css={{ flexGrow: 1, transform: ['scale(0.5)', 'scale(1)'] }}
          />
        </Flex>

        <Flex
          as="ul"
          sx={{
            mt: [4, 5],
            px: [4, 5],
            flexDirection: ['column', 'row'],
            alignItems: 'flex-end',
            justifyContent: 'space-around',
          }}
        >
          {LINKS.map(link => (
            <Flex
              as="li"
              key={link.href}
              sx={{
                mt: 2,
                '&:first-of-type': {
                  mt: 0,
                },
              }}
            >
              <MenuItem fontSize={2} {...link} />
            </Flex>
          ))}
        </Flex>
      </GlobalLayout>
    </>
  )
})
Page.displayName = 'Page'

export default Page
